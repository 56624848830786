import { useRoutes } from "react-router-dom";
import { useRouter } from "./hooks";
import routes from "./routes";
import * as FullStory from '@fullstory/browser';
import ChatbaseWidget from "./components/features/ChatbaseWidget";

export default function App() {
  const router = useRouter();

  FullStory.init({ orgId: 'o-1FK5YB-na1' });
  return (
    <>
      <ChatbaseWidget />
      {useRoutes(routes(router))}
    </>
  );
}
