import { useEffect, useMemo, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Card, ExpandIcon } from "../../../components";
import TaskItem from "./TaskItem";
import { useTutoringUser } from "../../../hooks";
import { XIcon } from "@heroicons/react/outline";

export default function Tasks() {
  const { actionItems: _ } = useTutoringUser();
  const [actionItems, setActionItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCompletedModal, setShowCompletedModal] = useState(false);
  const completedItems = useMemo(() => actionItems?.filter((task, index) => task["Completed"]) || [], [actionItems]);
  const uncompletedItems = useMemo(() => actionItems?.filter((task, index) => !task["Completed"]) || [], [actionItems]);

  const completeItem = (actionItemId) => {
    const updatedActionItems = actionItems.map((item) => {
      if (item["Action Item ID"] === actionItemId) {
        return { ...item, Completed: true };
      }
      return item;
    });
    setActionItems(updatedActionItems);
  };

  useEffect(() => {
    setActionItems(_);
  }, [_])

  const Header = () => {
    const count = uncompletedItems?.length || 0;
    return (
      <div className="relative">
        <div className="relative flex items-center gap-2 justify-center">
          <span className="relative font-bold text-xl text-[#1F2937]">Tasks</span>
          {count > 0 && (
            <span className="rounded-[50px] bg-[#EF4444] text-white flex items-center justify-center text-sm w-[18px] h-[18px]">
              {count}
            </span>
          )}
        </div>
        {count > 0 && (
          <div className="absolute right-0 top-0 mt-2 mr-2 z-30">
            <button className="bg-inherit border-none border-0" onClick={() => setShowModal(true)}>
              <ExpandIcon />
            </button>
          </div>
        )}
      </div>
    );
  };

  const Footer = () => {
    if (!completedItems?.length) return null;
    else
      return (
        <button className="bg-inherit border-none border-0 text-sm text-[#0D9488]" onClick={() => setShowCompletedModal(true)}>
          View Completed Tasks
          <ChevronRightIcon className="h-4 w-4 inline-block ml-1" />
        </button>
      );
  };

  return (
    <>
      <Card header={<Header />} footer={<Footer />}>
        {uncompletedItems?.length > 0 ? (
          uncompletedItems
            ?.map((task, index) => <TaskItem key={index} {...task} completeItem={completeItem} />)
        ) : (
          <div className="text-xs text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">
            No available task
          </div>
        )}
      </Card>
      {showModal && (
        <div className="fixed flex items-center justify-center top-0 left-0 w-screen h-screen bg-opacity-50 bg-gray-500">
          <div className="relative flex flex-col bg-white rounded-3xl overflow-hidden min-h-[80vh] items-center shadow-md w-full max-w-2xl max-h-[calc(100%-100px)]">
            <div className="w-full">
              <div className="text-3xl font-bold pt-8 pl-8 pb-4 text-black">Tasks</div>
              <button onClick={() => setShowModal(false)} className="absolute top-5 right-5 text-black">
                <XIcon className="w-5 h-5" />
              </button>
            </div>
            <div className="sm:px-10 px-5 pb-5 w-full overflow-auto">
              <div className="p-4">
                {actionItems?.length > 0 ? (
                  [...uncompletedItems, ...completedItems]?.map((task, index) => <TaskItem key={index} {...task} />)
                ) : (
                  <div className="text-xs text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">
                    No available task
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showCompletedModal && (
        <div className="fixed flex items-center justify-center top-0 left-0 w-screen h-screen bg-opacity-50 bg-gray-500">
          <div className="relative flex flex-col bg-white rounded-3xl overflow-hidden min-h-[80vh] items-center shadow-md w-full max-w-2xl max-h-[calc(100%-100px)]">
            <div className="w-full">
              <div className="text-3xl font-bold pt-8 pl-8 pb-4 text-black">Completed Tasks</div>
              <button onClick={() => setShowCompletedModal(false)} className="absolute top-5 right-5 text-black">
                <XIcon className="w-5 h-5" />
              </button>
            </div>
            <div className="sm:px-10 px-5 pb-5 w-full overflow-auto">
              <div className="p-4">
                {completedItems?.length > 0 ? (
                  completedItems?.map((task, index) => <TaskItem key={index} {...task} />)
                ) : (
                  <div className="text-xs text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">
                    No available task
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
