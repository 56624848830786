import { useEffect } from 'react';

export default function ChatbaseWidget() {
  useEffect(() => {
    // Check if script is already loaded
    if (document.getElementById('chatbase-widget-script')) {
      return;
    }

    // Create and append the initialization script
    const initScript = document.createElement('script');
    initScript.id = 'chatbase-widget-script';
    initScript.innerHTML = `
      window.chatbaseConfig = {
        chatbotId: 'fo9HDzr2B6O1vhXVrqNzE'
      };
      
      (function(){if(!window.chatbase||window.chatbase("getState")!=="initialized"){window.chatbase=(...arguments)=>{if(!window.chatbase.q){window.chatbase.q=[]}window.chatbase.q.push(arguments)};window.chatbase=new Proxy(window.chatbase,{get(target,prop){if(prop==="q"){return target.q}return(...args)=>target(prop,...args)}})}const onLoad=function(){const script=document.createElement("script");script.src="https://www.chatbase.co/embed.min.js";script.id="fo9HDzr2B6O1vhXVrqNzE";script.domain="www.chatbase.co";document.body.appendChild(script)};if(document.readyState==="complete"){onLoad()}else{window.addEventListener("load",onLoad)}})();
    `;
    document.head.appendChild(initScript);

    return () => {
      // Only remove our initialization script on unmount
      const script = document.getElementById('chatbase-widget-script');
      if (script) {
        script.remove();
      }
    };
  }, []);

  return null;
}
