const tabs = [
  {
    name: "General Expectations",
    src: "https://docs.google.com/forms/d/e/1FAIpQLScN2qPXuWx7PcG4rAPzFUx06M-V5Ahtx0o-ge1L3ifc-9VVFQ/viewform?embedded=true",
    status: false,
    statusKey: "workbookForm1Completed",
    entryFields: {
      name: "entry.1506871634",
      email: "entry.147453066"
    }
  },
  {
    name: "Tutoring in Reading and Math",
    src: "https://docs.google.com/forms/d/e/1FAIpQLSca40szBn7VgKf8QGM1nhAzrh0wumXnfAdWqsV9r5EZq4eCdA/viewform?embedded=true",
    status: false,
    statusKey: "workbookForm2Completed",
    entryFields: {
      name: "entry.1875322235",
      email: "entry.1712574924"
    }
  },
  {
    name: "Tutor Portal",
    src: "https://docs.google.com/forms/d/e/1FAIpQLSdJpB6Q3-f5hRQ1iHlep7wuURpeyqyLevRaPh-Vxu4_tpGlpw/viewform?embedded=true",
    status: false,
    statusKey: "workbookForm3Completed",
    entryFields: {
      name: "entry.1506871634",
      email: "entry.689630015"
    }
  },
  {
    name: "Becoming a High Impact Tutor",
    src: "https://docs.google.com/forms/d/e/1FAIpQLScewxx296TOYqKFJEPDKKzPi56A-bvKdBoZG3OtDhzAnkMNZQ/viewform?embedded=true",
    status: false,
    statusKey: "workbookFormReviewCompleted",
    entryFields: {
      name: "entry.1935659664",
      email: "entry.817090880"
    }
  },
];

export default tabs;