import React from "react";
import {
  Alert,
  Card,
  CircularIcon,
  ExternalLink,
  Heading,
  SolidButton
} from "../../../components";
import { ALERT_VARIANTS } from "../../../constants/constants";

export default function BackgroundCheckCA() {
  return (
    <>
      {/* Step 1 */}
      <Card>
        <div className="flex items-baseline mb-4 space-x-4">
          <CircularIcon>1</CircularIcon>
          <Heading>Print and Fill Out Form</Heading>
        </div>
        <p>
  Click on the button below to view the Live Scan form. You may either print or send a completed form to your Live Scan appointment. For more information on filling out the form, please watch the video below!
</p>
<div className="my-4 flex">
<iframe width="700" height="438" src="https://www.youtube.com/embed/oVrL_gB2kOQ?si=DFKXA3-FsapV0Jb0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
        <Alert variant={ALERT_VARIANTS.INFO}>
          <p>
            You do not need to fill out the Contact Telephone Number, OCA
            Number, Billing Number, or Misc. Number.
          </p>
        </Alert>

        <div className="max-w-xs mx-auto">
          <ExternalLink href="https://drive.google.com/file/d/1D7K2aOsQ3NQTF1Z5uIvt0K8LHN-Ql1Bw/view">
            <SolidButton name="View Live Scan Form"/>
          </ExternalLink>
        </div>
      </Card>

      {/* Step 2 */}
      <Card>
        <div className="flex items-baseline mb-4 space-x-4">
          <CircularIcon>2</CircularIcon>
          <Heading>Get Fingerprinted</Heading>
        </div>

        <div className="space-y-4">
          <p>
            Once you receive and complete the necessary forms from the applicant
            agency, visit a local Live Scan site to be fingerprinted.
          </p>

          <div>
            <p className="font-medium">Find a nearby site and their fees:</p>

            <ul className="list-disc px-6 text-turquoise">
              <li>
                <ExternalLink href="https://www.google.com/search?sa=X&tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=AOaemvJBofUDn16eg2D94bwErT9nWqNUwg:1630567059485&q=livescan+near+me">
                  Live Scan with Google Maps
                </ExternalLink>
              </li>
              <li>
                <ExternalLink href="https://docs.google.com/document/d/1M_ZBQeidwncFqY4vR3bPNKM30_dGhEl5AcojcwCvnVw/edit">
                  Live Scan by County
                </ExternalLink>
              </li>
              <li>
                <ExternalLink href="https://oag.ca.gov/fingerprints/locations">
                  DOJ Live Scan Locations
                </ExternalLink>
              </li>
            </ul>
          </div>
        </div>

        <Alert variant={ALERT_VARIANTS.WARN}>
          <p>
            You must present valid photo identification when being
            fingerprinted. Expired identification information will not be
            accepted.
          </p>
        </Alert>

        <div className="space-y-4">
          <p>
            A fingerprint-rolling fee will be collected when you get your
            fingerprints taken. Since this fee varies widely among locations,
            you will want to review the cost before going to a fingerprinting
            site. Be sure to check for any restrictions on method of payment,
            such as cash or money order only. The List of Applicant Live Scan
            Sites provides information by location on fees, hours of operation
            and if an appointment is needed.
          </p>

          <p>
            There also is a criminal history processing fee collected by the DOJ
            and Federal Bureau of Investigation (FBI) for the background checks.
            While often paid by the requesting agency, some applicants may be
            required to pay this fee themselves, so check with your requesting
            applicant agency. While the law requires applicant fingerprints to
            be captured and submitted electronically via Live Scan, DOJ does
            have limited statutory authority to issue an exemption to this
            mandate if an electronic transmission site is regionally unavailable
            or internal processing procedures dictate a need.
          </p>
        </div>
      </Card>

      {/* Step 3 */}
      <Card>
        <div className="flex items-baseline mb-4 space-x-4">
          <CircularIcon>3</CircularIcon>
          <Heading>Wait for Your Results</Heading>
        </div>

        <p>
          Once the submission is received and processed, the DOJ will respond to
          the applicant agency either electronically or via U.S. mail. Step Up
          Tutoring will let you know when we have received your results. This
          entire process will only take a couple days so you can start tutoring
          in no time!
        </p>

        <Alert variant={ALERT_VARIANTS.INFO}>
          <p>
            Please keep your form in a safe place (you can also take a picture).
            In the case that your results are lost or delayed, we can search
            with your date of birth and AIT number (which should be written by
            the Live Scan attendant).
          </p>
        </Alert>
      </Card>
    </>
  );
}
